import request from '../request'
import axios from 'axios'

const token = localStorage.getItem('Blade-Auth-Token');

export const getFeedBackList = (params) => {
    return request({
        url: `/blade-party-admin/dingtalk/affairsopinioninfos/page`,
        params: params,
        method: 'GET',
    })
};

export const getFeedBackManageList = (params) => {
    return request({
        url: `/blade-party-admin/dingtalk/affairsopinioninfos/manage/page`,
        params: params,
        method: 'GET',
    })
};

export const getFeedBackDetail = (params) => {
    return request({
        url: `/blade-party-admin/dingtalk/affairsopinioninfos/detail`,
        params: params,
        method: 'GET',
    })
};

export const saveFeedBack = (params) => {
    return request({
        url: `/blade-party-admin/dingtalk/affairsopinioninfos/save`,
        data: params,
        method: 'POST',
    })
};

export const replyFeedBack = (params) => {
    return request({
        url: `/blade-party-admin/dingtalk/affairsopinioninfos/reply`,
        data: params,
        method: 'POST',
    })
};

export const getDictionary = (params) => {
    return request({
        url: `/blade-party-admin/dict/dictionary`,
        params: params,
        method: 'GET',
    })
};

// 文件上传
export const uploadFile = (params) => {
    return axios.post(`/blade-party-admin/file/put-file`, params, { headers: { 'Blade-Auth': token, 'User-Type': 'DINGTALK', 'Content-Type': 'multipart/form-data' } });
};